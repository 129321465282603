const musicData = [
  {
    year: 2001,
    tracks: [
      {
        title: 'Intrusion Of Peace',
        description: 'Kim Menard\'s magnum opus, the 2001 album "Intrusion of Peace", is kicked off with the titular track. Experience a sweeping journey across his musical expressions, all written and produced himself. Classic rock synths meet with the sound of the peerless Martin D-12 acoustic guitar. The album received limited radio play across different areas of the state of Texas during his life and sold a few hundred copies, which he produced with the help of a Canadian firm.',
        src: require('../assets/music/intrusion-of-peace.mp3')
      },
      {
        title: 'Christian\'s Song',
        description: 'A song for solo guitar, named for Kim\'s youngest son, the pride and joy of his latest years. Composed and performed by the man himself. As a musician Kim was always most comfortable with a guitar and found his most important expressions using that instrument as a vehicle.',
        src: require('../assets/music/christians-song.mp3')
      },
      {
        title: 'I Could Sing of Your Love Forever',
        description: 'Borrowing from a well-known spiritual song, Kim produces a broad, pulsing canvas against this impressionist and synth-centric rendition. With a length of nearly 15 minutes, this song pushes into the reaches of endurance, vividly tracing the notion of "forever" into the work in a concrete way.',
        src: require('../assets/music/i-could-sing-of-your-love-forever.mp3')
      },
      {
        title: 'Prelude to Jonah',
        description: 'Using the Morpheus Z Plane and his trusty Roland RD-500, here Kim lays out an embellished version with flying lead lines (maybe his most identifiable sound) to pave the way for the heaviest track on the album. Long, expressive passages here continually reference forward to that theme, producing a meditative experience more than nine minutes long and bursting full of improvisation.',
        src: require('../assets/music/prelude-to-jonah.mp3')
      },
      {
        title: 'Jonah',
        description: 'About a decade earlier, Kim Menard composed the music for a stage musical by the same name, which culminated in series of 4 performances over the course of two weekends. The theme to this track reproduces the major signing number in the stage performance, but this version goes into more contemplative musical territory. The major theme is restated several times across the 10 minutes of this epic exploration.',
        src: require('../assets/music/jonah.mp3')
      },
      {
        title: 'I Will Enter',
        description: 'Presumably a shortened version of one of Kim\'s favorite church songs, "I Will Enter His Gates", this laid-back and lush instrumental mixes Menard\'s familiar sweeping sonic landscapes with a certain reverent touch nodding to the sacred music that inspired it.',
        src: require('../assets/music/i-will-enter.mp3')
      },
      {
        title: 'Intrusion Of Peace - Slight Return',
        description: 'One of his favorite artists was the legendary guitarist Jimi Hendrix, whose famous suffixing of his hit "Voodoo Chile" here gets a wink from Kim Menard as he does the same thing Hendrix did; rips and shreds an electric guitar over the theme of the first song. Some of his most emotively revealing work is here, where the rest of the album tends a bit further away from the gritty rock-and-roll of his younger years, never forgotten or cheaped in his eyes. Lead line from the beloved Yakima guitar, whose tone is distinct and full of fire.',
        src: require('../assets/music/intrusion-of-peace-slight-return.mp3')
      },
      {
        title: 'Island Aire',
        description: 'Kim Menard didn\'t often broadcast his love of bluegrass and fingerpicking, but it was a core of what drove him to practice in his more mature years, when he had children in the house. He would also incorporate things like Celtic jigs, where repetitions and patterns formed meditative spaces. This is one of those that he developed into a fuller song. ',
        src: require('../assets/music/island-aire.mp3')
      },
      {
        title: 'Emerald City',
        description: 'A fiercely proud Pacific North Westerner, Kim Menard spent most of his years living far away from the land of his birth. The title of this song refers to a famous nickname for Seattle, Washington, for which he had a passion that he shared with his children. Dedicated to his son and daughter-in-law, Jared and Erin Menard, who still live in the area.',
        src: require('../assets/music/emerald-city.mp3')
      },
      {
        title: 'The Love In Your Eyes',
        description: 'A sweet, intimate solo guitar work that Kim dedicated to his wife Susan, whose tireless and selfless love and care helped him weather the most difficult years of his life and supported him across a long marriage, raising him four fine children. This track features some of the most intricate fingerpicking work that he ever attempted.',
        src: require('../assets/music/the-love-in-your-eyes.mp3')
      },
    ],
  },
    {
      year: 2005,
      tracks: [
        {
          title: 'Serenade for Strings No. 1',
          description: 'After leaving the service of the United States Air Force, Jared Menard taught himself how to read and write music from a textbook called "Tonal Harmony" and started studying composition in earnest. Few of his earliest efforts survive, but a trio of string quartets did manage to make the trip through time. The inspiration for the first motif in this song came from a real bird singing outside the window in Magnolia one fine Texas morning.',
          src: require('../assets/music/serenade-for-strings-1.mp3')
        },
        {
          title: 'Serenade for Strings No. 2',
          description: 'Jared wrote three of these little quartets, and there might have been 3 or 4 people in the world who he managed to have listen to them. The production was accomplished using a program called "Finale" and VSTs from the East West Symphonic Orchestra. These pieces help to mark out the first boundaries of a developing compositional style.',
          src: require('../assets/music/serenade-for-strings-2.mp3')
        },
        {
          title: 'Serenade for Strings No. 3',
          description: 'The last of the trio, like the second, takes the theme from the first serenade and twists it around again, producing a different and more discordant product here and there; as a newborn composer, Jared was not as adventurous in producing disonnance as he would shortly become. He was also working out techniques of tempo and dynamic control, with which a lot of different effects are attempted in this piece.',
          src: require('../assets/music/serenade-for-strings-3.mp3')
        },
      ],
    },
    {
      year: 2007,
      tracks: [
        {
          title: 'Scherzo No. 1',
          description: 'Emboldened for absolutely no reason at all (as no one had reacted positively to his previous efforts in this area), Jared began experimenting with writing for larger ensembles, eventually generating full orchestral scores of various ideas. The Scherzo here is a short, repetitive piece which cycles like a dance, designed to loop itself smoothly from start to finish. This theme appears in much of Jared\'s work.',
          src: require('../assets/music/scherzo.mp3')
        },
        {
          title: 'Impromptu No. 2',
          description: 'Jared wrote dozens of pieces that did not adhere to any particular classical form, and decided to call them "Impromptus" in the very old tradition. Only the one designated "number two" survived to the present. One other understanding of this piece could be as a sort of fanfare - although, to what, it is harder to say.',
          src: require('../assets/music/impromptu2.mp3')
        },

      ],
    },
    {
      year: 2008,
      tracks: [
        {
          title: 'Piano Sonata No. 2 - Allegro',
          description: 'Jared went through a period where he was studying classical song forms and became obsessed with sonatas for many years. He wrote four and a half sonatas for piano, although the first was based on a musical theme suggested by one of his friends at the time, Richard Anson, and was lost to time, and the fifth was only half-finished.',
          src: require('../assets/music/sonata2-1.mp3')
        },
        {
          title: 'Piano Sonata No. 2 - Scherzo',
          description: 'Sonata form in the strict classical sense usually involve a single musical idea, or theme, being considered from several different approaches in terms of tonality, timing, or expressive freedom. These different approaches are given names according to their song form or suggested tempo. This one is a short dance in the familiar "scherzo" form.',
          src: require('../assets/music/sonata2-3.mp3')
        },
        {
          title: 'Piano Sonata No. 2 - Rondo',
          description: 'A convention in piano sonatas from the Classical period has them including (and often concluding on) a round or fugue form, here the "rondo". This piece reclaims elements of the theme first presented in the Allegro section but reframes and presents them with a completely different energy, exposing a little more of their dynamic potential.',
          src: require('../assets/music/sonata2-4.mp3')
        },
      ],
    },
    {
      year: 2009,
      tracks: [
        {
          title: 'Piano Sonata No. 3 - Allegro Cantabile',
          description: 'Where his second Piano Sonata mostly embraces classical types of elaboration and harmony, Jared started branching into more impressionistic compositions with his third Piano Sonata, drawing some inspiration from American music in the 1950\'s and some more relaxed tonality. In the third sonata Jared\'s playful and meandering compositional style becomes more developed. The performance marking on this one, Allegro Cantabile, means "fast and as if singing".',
          src: require('../assets/music/sonata3-1.mp3')
        },
        {
          title: 'Piano Sonata No. 3 - Adagio',
          description: 'Taking the original theme in a nearly unrecognizable direction and experimenting more liberally with dissonance and challenging harmonies, the Adagio is a brisk walk through a corridor of crumbling facades.',
          src: require('../assets/music/sonata3-2.mp3')
        },
        {
          title: 'Piano Sonata No. 3 - Rondo',
          description: 'Finishing again on a round, this Rondo showcases a pair of musical ideas and plays them repeatedly against one another. The theme from the start is subtlely present, but is controlled and directed by the recapitulation. The working out becomes almost mathematical - to be performed with great gusto.',
          src: require('../assets/music/sonata3-4.mp3')
        },
      ],
    },

    {
      year: 2011,
      tracks: [
        {
          title: 'The Complications of Our Lives - Kim\'s version',
          description: 'Not a part of the Intrusion of Peace album, an aging and ailing Kim Menard wrote and dedicated this song to his son, Jared. Reflecting the closeness of their musical likenesses together with the growing distance between their lives, as his son was growing a new life for himself halfway across the country, this poignant track is a beautiful rendering of the joy and pain that a father may get from a son who is more like himself than he\'d sometimes like.',
          src: require('../assets/music/the-complications-of-our-lives.mp3')
        },
        {
          title: 'The Complications Of Our Lives - Jared\'s version',
          description: 'After his father wrote and dedicated the song of the same name, Jared produced a version of the song as performed by a string quartet. His composition, however, differs from his father\'s in other significant respects, as elements of the tonality, timing, and melody are all changed in expressive ways. Jared planned that this piece should reflect the sort of joy and pain that a son might have for their father, if he were more like his father than he liked sometimes.',
          src: require('../assets/music/complications.mp3')
        },
      ],
    },
    {
      year: 2012,
      tracks: [
        {
          title: 'Gemini',
          description: 'Work started on the massive Constellations project around 2007. At the time, Jared saw it as a giant "fantasy" piece, which would have 12 parts, one for each of the astrological constellations. The melodies in each would have their different fluctuations determined by the positions of the physical stars themselves, adding an element of randomness to the first generative process. In the final score of that work, of the 12 which were committed to paper, only four recordings survive to date. The first of these was Gemini.',
          src: require('../assets/music/gemini.mp3')
        },
        {
          title: 'Pisces',
          description: 'As Jared was working at composing the Constellations project, he would often draw inspiration for musical moods and interpretations from the people in his life who bore the horoscope sign in question. Pisces took inspiration from several people, but in particular his wife\'s brother Jeffrey; for example, the major theme in Pisces is often given to the trombones to play, simply because Jeffrey was a skillful trombone player.',
          src: require('../assets/music/pisces.mp3')
        },
        {
          title: 'Sagittarius',
          description: 'When writing the Sagittarius, Jared\'s mind was firmly centered around two of those in his family; his younger sister Michelle and his father, Kim. A number of his feelings for both of those beautiful people are reflected in the composition directly, including two parts which are easily identifiable. The first is the signature "hunting call" in perfect fifths of the French Horns, which appears several times in the work - this is a reference to a music effect his father loved. Secondly, the orchestration at one point renders down to a single flute line, which traces out a tender and sad melody. This is thought to reflect the love and sadness he felt for Michelle; almost ten years in age separated them, so that he had left his parent\'s house while she was a small child, and never returned for any substantial period.',
          src: require('../assets/music/sagi.mp3')
        },
        {
          title: 'Scorpio',
          description: 'Jared often regarded the "Scorpio" as his finest work among all the Constellations. In terms of astrology, he regarded members of that sign to be his nemesis in the world, a force counteracting him directly when he engaged in relationships with them. A lot of that fanciful attitude was only half-serious, but several major relationships with Scorpios had made a powerful impression on him. Perhaps it was that energy that went into the crafting of a delicate, ruminating melody with equal parts of sweet harmony and immense discordance. One picture which pops up many times in the piece is the archetype of flying too close to the sun, so your wax wings are melted.',
          src: require('../assets/music/scorpio.mp3')
        },
      ],
    },
    {
      year: 2015,
      tracks: [
        {
          title: 'Too Good To Be True',
          description: 'The earliest surviving recording of Jared\'s singing voice. Now, we\'re not saying that is a good thing, but it IS a thing. This is a laid-back cover of the jazz standard, somewhat more in the style of Lauren Hill\'s great version. For a refreshing break from previous recordings shown here, Jared also played all of the instruments himself here, including the bass and acoustic guitars and djembe.',
          src: require('../assets/music/too-good-to-be-true.mp3')
        },
        {
          title: 'The Boy In The Bubble',
          description: 'In 2015, Jared decided he would cover all of the tracks on Paul Simon\'s "Graceland", and several recordings have been found that show how far he got. For this undertaking, he used his Ibanez Euphoria, Fender P-Bass, and his trusty 14" djembe. He would go on years later to record these songs again using new techniques and tools.',
          src: require('../assets/music/boy-in-the-bubble.mp3')
        },
        {
          title: 'I Know What I Know',
          description: 'This version of Paul Simon\'s classic is converted into a minor key and performed with a piano, single cello, and one voice. Jared asked himself later, "why did you have to make it so depressing?" But art imitates life, like they say, and sometimes you cannot escape feeling sad.',
          src: require('../assets/music/i-know-what-i-know.mp3')
        },
        {
          title: 'Graceland',
          description: 'Jared attempted to cover the titular track from Paul Simon\'s great album here with a mix of guitar and his trusty djembe. Musically he doesn\'t manage as cohesive a product has he would like; these early years recordings are quite inconsistent with respect to audio quality, as Jared was still developing his production style and learning his tools.',
          src: require('../assets/music/graceland.mp3')
        },
      ],
    },
    {
      year: 2016,
      tracks: [
        {
          title: 'Piano Sonata No. 4 - Allegro',
          description: 'Jared Menard\'s father, Kim Menard, died in 2016, and that event serves as the centerpiece to the whole composition, which is at times ecstatic and frenzied, other times painfully gloomy and unreachable. The first movement is light-hearted and celebrates a major theme with jazz-like shifts in the rhythm.',
          src: require('../assets/music/sonata4-1.mp3')
        },
        {
          title: 'Piano Sonata No. 4 - Adagio',
          description: 'Taking the original theme in a nearly unrecognizable direction and experimenting more liberally with dissonance and challenging harmonies, the Adagio is a brisk walk through a corridor of crumbling facades.',
          src: require('../assets/music/sonata4-2.mp3')
        },
        {
          title: 'Piano Sonata No. 4- Scherzo',
          description: 'Does it always have to have a scherzo? Jared asked himself this and answered, "Yes". The third movement of a sonata or symphony is generally in some sort of dance time, or in 3s, and the scherzo form consistently gave Jared a preferred vehicle for meeting that convention. This light-hearted piece reflects a contained, but practically bursting, fun.',
          src: require('../assets/music/sonata4-3.mp3')
        },
        {
          title: 'Piano Sonata No. 4 - Scherzo',
          description: 'The Rondo that ends the fourth piano sonata returns the original theme in the same key, elaborated and extended a little. A repeated dissonant pair of notes functions as a bookmarking motif between different expressive sections. The recapitulation works through the same theme, and although some further discoveries are made, in the end the grief is not overcome by the working out.',
          src: require('../assets/music/sonata4-4.mp3')
        },
      ],
    },
    {
      year: 2017,
      tracks: [
        {
          title: 'The Swan',
          description: 'In 2017 Jared finished a collection/rearranging/recomposition of the largest single work in his life, the Constellations. This effort involved taking the paper manuscript of his older work and re-envisioning it as a performance for orchestra and rock band, including drums, bass and guitar. Most of the works would also experience a change in time signature, and several short "threads" of musicality were connected so that the whole, continuous piece had 23 parts. Also, he decided at this time to ditch the astrological imagery in the titling, and instead reverted to the simple names of the constellations, all of which match spatially across the western night sky.',
          src: require('../assets/music/01 - The Swan.mp3')
        },
        {
          title: 'The Water Bearer',
          description: 'Originally "Aquarius", Jared drew inspiration for this piece from his relationship with his older sister Elizabeth. In their younger years they enjoyed a close friendship, although they too would drift apart over the years and through living far away from one another. This piece tries to capture the unassuming strength of the water bearers, those whose tireless efforts sustain for those they care for.',
          src: require('../assets/music/02 - The Water Bearer.mp3')
        },
        {
          title: 'The Fishes',
          description: 'The re-working of the Pisces here still has the trombones rumbling through the introduction, a tip of the hat to Jared\'s brother-in-law Jeffrey. The addition of the drum kit and bass guitar here add a gritty, groovy element well-suited to Jared\'s own tastes in progressive rock areas. Fun story - he once drew out the score for this musical piece\'s main theme in the hopes of having it tattooed on his left arm, but did not find a tattoo artist able or willing to do it.',
          src: require('../assets/music/03 - The Fishes.mp3')
        },
        {
          title: 'The Vain Queen',
          description: 'This short dramatic piece connects the Pisces constellation to Aries and is the name Jared selected to represent Cassiopeia. As with every track in Constellations, the melodic ideas here were not conceived, but translated from the spatial positions of the stars in the constellation. Around that, the composer added a harmonic and rhythmic structure to make sense of it from a musical perspective.',
          src: require('../assets/music/04 - The Vain Queen.mp3')
        },
        {
          title: 'The Vain Queen',
          description: 'This short dramatic piece connects the Pisces constellation to Aries and is the name Jared selected to represent Cassiopeia. As with every track in Constellations, the melodic ideas here were not conceived, but translated from the spatial positions of the stars in the constellation. Around that, the composer added a harmonic and rhythmic structure to make sense of it from a musical perspective.',
          src: require('../assets/music/04 - The Vain Queen.mp3')
        },
        {
          title: 'The Ram',
          description: 'The Ram represents the constellation Aries, one of the twelve zodiac signs. In Greek mythology, Aries is associated with the Golden Ram, whose fleece became the legendary Golden Fleece sought by Jason and the Argonauts. The ram symbolizes courage and the spirit of adventure. Jared only knew of a few people who identified themselves as Aries, and thought for a long time that it would be the sign he was least equipped to understand in any concrete way, being a Libra himself.',
          src: require('../assets/music/05 - The Ram.mp3')
        },
        {
          title: 'The Gorgon Slayer',
          description: 'Referring to the constellation Perseus, named after the Greek hero who slew Medusa, the Gorgon with snakes for hair whose gaze could turn people to stone. Perseus used gifts from the gods, including a mirrored shield and winged sandals, to accomplish his quest. Jared decided to portray the hero with a blistering guitar solo line, in the fine old rock and roll tradition. There is a short period where it then merges into something more contemplative, in anticipation of the next piece.',
          src: require('../assets/music/06 - The Gorgon Slayer.mp3')
        },
        {
          title: 'The Bull',
          description: 'No relationship with another Zodiac sign is quite as convoluted and fraught as Jared had with Taurus, his mother Susan\'s sign. In mythology, Taurus is linked to the story of Zeus transforming into a white bull to abduct Europa, a Phoenician princess. The constellation symbolizes strength and sensuality, featuring the bright star Aldebaran and the Pleiades cluster. Jared\'s interpretation overflows uncontrollably with musical allusions to his own thoughts and feelings toward his mother, and toward motherhood in general, and as such is probably rife with material a psychoanalyst would find fascinating. As a piece of music, it features a somewhat rigid, repeating structure with an insistent melody which, though it undergoes many transformations, never changes itself at the core.',
          src: require('../assets/music/07 - The Bull.mp3')
        },
        {
          title: 'The Hunter',
          description: 'Written facing  Orion, one of the most recognizable constellations in the night sky. Orion was a mighty hunter in Greek mythology. The constellation depicts him with his belt and sword, accompanied by his hunting dogs, and represents prowess and the eternal pursuit of his prey. This is a short piece which seeks to show some of the comfort of immutability and the reliability of eons.',
          src: require('../assets/music/08 - The Hunter.mp3')
        },
        {
          title: 'The Twins',
          description: 'You may find some interest here in comparing this version of Gemini, 5 years later and put distinctly into a progressive rock space, with the purely orchestra version of earlier. Gemini is associated with the twin brothers Castor and Pollux in Greek mythology. Castor was mortal and Pollux immortal. Their bond was so strong that they were placed among the stars to remain together forever. Gemini symbolizes brotherhood and duality. The duality was Jared\'s focus in the composition, as any important element occurs in a repetition or a pattern of twos throughout. Jared always felt a sort of instant kinship with the other two Air-element signs, the third being Aquarius.',
          src: require('../assets/music/09 - The Twins.mp3')
        },
        {
          title: 'The Chariot Rider',
          description: 'Similarly to how the previous "Hero" constellation was addressed, another lead guitar solo is presented for Auriga, the constellation depicting a charioteer. Associated with Erichthonius of Athens, who invented the four-horse chariot, or sometimes with Myrtilus, a charioteer cursed by Pelops. The constellation represents innovation, skill, and mastery in driving the chariot of progress. The decision to present the musical device this way, in arpeggios as opposed to a structure involving other instruments and chords, repeats the pattern (which will appear again).',
          src: require('../assets/music/10 - The Chariot Rider.mp3')
        },
        {
          title: 'The Crab',
          description: 'Among all of the constellation pieces, the Crab occupies a special position. It was the first to be written, and was in character meant to resemble a "music box" effect. Jared based the majority of his emotional interpretation of this piece on his feelings for one of his life\'s best friends, Eric Brandon, who was a co-worker and roommate for many years. In mythology, Cancer was the giant crab sent by Hera to distract Hercules during his battle with the Hydra. Though crushed, Hera placed it among the stars for its efforts. Cancer symbolizes tenacity and the protective nature of the shell.',
          src: require('../assets/music/11 - The Crab.mp3')
        },
        {
          title: 'The Dragon',
          description: 'This short orchestral piece refers to Draco, a constellation winding around the North Star. In mythology, Draco is associated with Ladon, the dragon guarding the golden apples in the Garden of the Hesperides. Slain by Hercules, the dragon was placed in the sky. The constellation embodies vigilance and guardianship. The strings and winds were chosen as the primary vehicles for the first half, then brass is gradually introduced, and the new theme only emerges gradually, as the Dragon is never forced to do anything it does not wish.',
          src: require('../assets/music/12 - The Dragon.mp3')
        },
        {
          title: 'The Lion',
          description: 'A dear friend of Jared\'s childhood and younger years, Daniel Diehl, was a drummer and a Leo. When Jared set out to write the Leo, he felt that the correct starting place was a pattern on a single drum. The rhythmic elements of this piece are expressive, more so than anywhere else, by virtue of that relationship. Linked to the Nemean Lion in Greek mythology, a fearsome beast slain by Hercules as one of his twelve labors. Hercules wore the lion\'s impenetrable skin as armor. Leo symbolizes bravery, nobility, and leadership.',
          src: require('../assets/music/13 - The Lion.mp3')
        },
        {
          title: 'The Hydra',
          description: 'The Hydra is the largest constellation in the sky, and everyone is familiar with its represention of the multi-headed serpent slain by Hercules. Each time a head was cut off, two more would grow back, but Hercules overcame this challenge. Hydra symbolizes daunting obstacles and the persistence needed to overcome them. The music here notes some of that heroism, and sets up the powerful and implacable Virgo coming next.',
          src: require('../assets/music/14 - The Hydra.mp3')
        },
        {
          title: 'The Priestess',
          description: 'No sign seemed to appear around Jared in his life more often than the Virgo, for reasons that never made sense. The only female figure among the zodiac constellations, Virgo is often associated with goddesses like Demeter or Astraea and symbolizes purity, fertility, and the harvest. The constellation highlights themes of nurturing and the cycles of nature. In practice, Jared had a few individuals to think of when he wrote the Virgo, chiefly two close friends around that time, Eric Landon Bell and Joshua Butts. The composer in this work tries to show some of the mutability and always self-refreshing nature of the sign, as comfortable with partnership as without it.',
          src: require('../assets/music/15 - The Priestess.mp3')
        },
        {
          title: 'The Herdsman',
          description: 'This very short piece, only about 30 seconds, is meant to resemble a tide which rises up continually over itself. Refers to Boötes, a constellation depicting a herdsman or plowman. Associated with figures like Arcas or Icarius, Boötes represents diligence and the rewards of hard work. The constellation watches over the celestial bears, Ursa Major and Ursa Minor.',
          src: require('../assets/music/16 - The Herdsman.mp3')
        },
        {
          title: 'The Scales',
          description: 'When it came time to write the Libra, his own birth sign, Jared decided to lean into his own rock roots and enter with an acoustic guitar featuring heavily. Libra is the only inanimate object among the zodiac constellations - linked to themes of balance and justice, associations are with Themis or Astraea, goddesses of law and order. The constellation signifies equilibrium, fairness, and the weighing of souls. Though none of that ever made sense in the context of his own life, Jared never denied that he felt a little bit like an inanimate object himself sometimes. Joking aside, the music is restless and combatative, struggling to settle in any given key, but always building to something.',
          src: require('../assets/music/17 - The Scales.mp3')
        },
        {
          title: 'The True Hero',
          description: 'No guitar solo here, because it wouldn\'t suit the mood. Known for his incredible strength and completion of the twelve labors, Hercules symbolizes perseverance and heroism. The constellation depicts him kneeling, reflecting his eternal struggle and ultimate triumph. In that kneeling position, he expresses his theme with a single clarinet and acoustic guitar.',
          src: require('../assets/music/18 - The True Hero.mp3')
        },
        {
          title: 'The Scorpion',
          description: 'A lot was written about Jared and Scorpios in the track from 5 years earlier, so here we will just invite you to make a comparison in the evolution of style and accompanying changes in the composition. In mythology, Scorpius was sent to kill Orion, the hunter, as retribution for his arrogance. Both were placed in the sky on opposite sides to keep them apart. The constellation embodies transformation and the cycles of life and death. Jared chose to incorporate more guitars in this final version and sought out several heavy drum and bass progressive grooves to express these ideas with a little more liveliness.',
          src: require('../assets/music/19 - The Scorpion.mp3')
        },
        {
          title: 'The Fire Bringer',
          description: '"The Fire Bringer" alludes to Prometheus, the Titan who stole fire from the gods and gave it to humanity. The main theme in this track is carried by the French horns. This melody symbolizes rebellion against oppression and the quest for knowledge. Violins and violas support a second statement and lead the transition into the Archer.',
          src: require('../assets/music/20 - The Fire Bringer.mp3')
        },
        {
          title: 'The Archer',
          description: 'Like with the Scorpio, we wrote a lot about the Sagitarrius when it was presented earlier. This zodiac constellation is depicted as a centaur archer. Associated with the wise centaur Chiron or the satyr Crotus, Sagittarius embodies the pursuit of wisdom and the bridging of earthly and divine realms. The constellation highlights exploration and philosophical inquiry. From a compositional perspective, Jared here made sure to add an acoustic guitar as several key parts, which could only refer symbolically to his father, Kim Menard, who was first and foremost a guitar player. The woodwinds retain their role of expressing feelings about his relationship to his little sister, Michelle, as in the previous version, with wistful sadness.',
          src: require('../assets/music/21 - The Archer.mp3')
        },
        {
          title: 'The Lizard',
          description: 'Leading up to the final track here, the Lizard refers to Lacerta, a small and faint constellation in the northern sky. Introduced by astronomer Johannes Hevelius in the 17th century, Lacerta doesn\'t have ancient mythology but fills a gap between better-known constellations. It represents subtlety and the overlooked wonders of the night sky, which themes are expressed here with a warm, flowing string section.',
          src: require('../assets/music/22 - The Lizard.mp3')
        },
        {
          title: 'The Goat',
          description: 'The Goat is both the final track to Constellations and also one of the most packed with arcane symbolism. Jared\'s wife Erin bears this sign, and one cannot overstate her importance in every area of his mind and life. The constellation represents Capricornus, a zodiac constellation depicted as a sea-goat with the front half of a goat and the tail of a fish. Associated with Pan or the goat Amalthea, Capricornus symbolizes determination, resilience, and the ability to navigate both physical and spiritual realms. The composer tried to take those themes and express also his own worldly helplessness without that wonderful woman in these few, unsatisfactory short minutes.',
          src: require('../assets/music/23 - The Goat.mp3')
        },

      ],
    },
    {
      year: 2023,
      tracks: [
        {
          title: 'Sadness',
          description: 'In 2022 Jared and his family moved out to Olympia, Washington, where he had been wanting to be for a long time. A while after getting settled in, he pulled his dad\'s old keyboards out of storage and recorded a few tracks with them. The first was this, which was based on a very different song that he wrote for acoustic guitar back in his troubadour days around Magnolia, Texas.',
          src: require('../assets/music/sadness.mp3')
        },
        {
          title: 'Be Unbeatable',
          description: 'Using the incomparable RD-500, Jared produced this track and dedicated it to his longtime friend and mentor, Matthew Ellis. The piece was imagined while he was trying to think of what his voice as an artist should be looking to communicate to the world, and he decided that you could never go wrong with an encouraging, empowering message.',
          src: require('../assets/music/be-unbeatable.mp3')
        },
        {
          title: 'Feel Good Inc.',
          description: 'This cover of the well-known song by the Gorillaz strived to be note-for-note and reproduce all the same instruments, as close to a "true" cover as Jared was capable of making. The mastered track was produced by his brother, Christian, who was at the time staying at his house in Olympia.',
          src: require('../assets/music/feel-good-inc-cover.mp3')
        },
        {
          title: 'Want You Gone',
          description: 'Jared has always been inspired by Jonathan Coulton and his quirky, creative and often hilarious music. The ending theme of the game Portal 2, "Want You Gone" is one of my favorites, a sort of love song from a psychotic killer AI after you have thwarted all of her efforts and beaten the game. Jared made only minor changes to the structure to make it a little creepier and much heavier, and performed with an approximation of GLaDOS using a vocoder.',
          src: require('../assets/music/want-you-gone.mp3')
        },
        {
          title: 'Dragonball Theme',
          description: 'The popular Dragonball anime series has long been a part of Jared\'s leisure time. Here he pulls out the legendary Yakima guitar to rip through the theme song from the 1986 show, the one that started a long line of expansions and spin-offs. Always self-conscious of his voice, here he had decided temporarily to only use the "robot" vocoder effect he had worked out with the earlier "Want You Gone" conver. He attempted to be a true to the original as possible in this composition, and discovered that those Japanese dudes were seriously shredding.',
          src: require('../assets/music/dragonball.mp3')
        },
        {
          title: 'Magic In My Code',
          description: 'Still leaning into the "robot" voice effect, Jared was inspired by the Imagine Dragon\'s well-known song, "Magic In My Bones" and thought it would be funny to pretend a robot sung that song, and that it was singing about its code instead, as robots do not have bones. He is also experimenting in this track with different production techniques and instrumentation, as he tends to do.',
          src: require('../assets/music/magic-in-my-code.mp3')
        },
        {
          title: 'Flowers',
          description: 'Jared was drawn to Miley Cyrus\'s famous song (which would become the center of a lawsuit later, much to his amusement). Always a bit of a silly person from some perspective, Jared was irresistably tickled by the notion of a robot buying flowers for itself and found the whole project extremely funny.',
          src: require('../assets/music/flowers.mp3')
        },
        {
          title: 'You Can Call Me Al',
          description: 'After a long break, Jared returned to the project of covering all of Paul Simon\'s famous album "Graceland". One of his favorite tracks on that album, perhaps because of the music video featuring Chevy Chase, Jared decided to cover this one more or less straight-up, except for that gnarly slap bass solo in the middle, which has always been a little bit of magic.',
          src: require('../assets/music/you-can-call-me-al.mp3')
        },
        {
          title: 'Gumboots',
          description: '"Gumboots" is another song from "Graceland" by Paul Simon. Jared always thought of this one as Paul\'s ode to man-children, as in adult, mature men who behave like children do, always demanding their way, and never seeing their childishness. Such men are an embarassment to any man who manages to live his life respectfully, treating others fairly and never laying the blame for his own actions on another.',
          src: require('../assets/music/gumboots.mp3')
        },
        {
          title: 'Planet Graceland',
          description: 'The second time covering this song, Jared decided to be truer to the source material in terms of instrumentation. Only a slight change to the lyrics was made, so that the story of moving to Graceland involved space travel in "Sector 21-Z", although there is nothing wrong with Memphis and Jared was quite fond of it when he visited in the early 2000\'s.',
          src: require('../assets/music/planet-graceland.mp3')
        },
        {
          title: 'That Was Your Mother',
          description: 'Another track from Paul Simon\'s "Graceland", Jared decided to make several changes about this song to position is as to his own children, telling the story of the place and time he met his wife, Erin. He had a lot of fun with the production on this one and it was generally well-received, although a little off-putting to diehard Paul Simon fans. Jared pulled out the legendary Yakima guitar here to rip an honest-to-God solo.',
          src: require('../assets/music/that-was-your-mother.mp3')
        },
      ],
    },
    {
      year: 2024,
      tracks: [
        {
          title: 'Always',
          description: 'Jared decided to start writing original songs with singing in them again for a while, and the first produce here he decided to call "Always". This was in line with his earlier idea, that he should try and make songs that were uplifting, were about joy, or something positive. "Always" is about fidelity and the tender, sometimes painful, but always unchanging commitment of real love. A very long lead in establishes the tumultuous tone of what will follow. ',
          src: require('../assets/music/always.mp3')
        },
        {
          title: 'Lands Unseen',
          description: 'The music for this one was imagined first, and then Jared did something he hadn\'t done for a while, and wrote a poem to use for the lyrics. The result has a cycling pattern and a philosophical tone, isn\'t about anyone in particular, but more all of us in particular as personified by the one who dreams, here.',
          src: require('../assets/music/lands-unseen.mp3')
        },
        {
          title: 'Find Your Light',
          description: 'Another song hoping to send a positive message and work out a little frustration, "Find Your Light" identifies with the struggle of being lost in a wide world of shadows, but the repeated refrain "You can do it, you just need to find your light" is meant to give someone hope. And the composer hopes that it will do that someday. ',
          src: require('../assets/music/find-your-light.mp3')
        },
        {
          title: 'Claire de Lune',
          description: 'French impressionist composer Debussey might have been horrified at this slight expansion of his well-known and well-loved classic, but here Jared was interested in the unusual time-signature, and how it might be expressed by a rock band together with that sweet, singing piano part. Obviously not a new composition, but it is a novel interpretation, tame overall and meant to be more a "light snack" than a feast musically.',
          src: require('../assets/music/claire-de-lune.mp3')
        },
        {
          title: 'Yellowberry Tea',
          description: 'A warm, relaxing journey through soft fields of flowing yellow leaves, Jared discovered a certain chord that summed of the feeling of not wanting to go anywhere else for him, and built up a composition around it. A funky backbeat and cheerful horns lead the way, with a bridge section which injects some contrasting elements in a distant key.',
          src: require('../assets/music/yellowberry-tea.mp3')
        },
        {
          title: 'Prelude in C Sharp Minor',
          description: 'Russian composer Sergei Rachmaninoff came onto Jared\'s radar in the movie "Shine" with Geoffrey Rush playing a brilliantly talented pianist wrestling with the learning of his notorious 2nd piano concerto. His Prelude, however, is much better known and is not as significantly challenging to learn for a training pianist. This version is a significant reorchestration of that piece, attempting to capture its gory, gothic heaviness with a hard rock band setting. The significant accomplishment of this piece is that it does not change a single note of the original composition, only places them with a different instrument, or slightly staggered in time, or sometimes a short ostinato.',
          src: require('../assets/music/prelude-in-c-sharp-minor.mp3')
        },
        {
          title: 'Breathe In, Breathe Out, Move On',
          description: 'American philosopher and musician Jimmy Buffett passed away in 2024, and Jared decided to make his own meager tribute to his life and career by covering a favorite song of his. The great songster\'s legacy is vast, with many brilliant works, but this one was selected as it reflects the parts of Jimmy\'s philosophy that Jared learned the most from, in terms of application to his own life. This song is about understanding that tragedy may come, but understanding that it is not an interruption of life, but an inseparable part of it.',
          src: require('../assets/music/breathe-in-breathe-out-move-on.mp3')
        },
        {
          title: 'Yo Ho, Yo Ho, A Pirate\'s Life For Me',
          description: 'On Annual Pirate\'s Day in 2024, Jared decided to cover a pirate standard. He envisioned this song expressively as a sort of narrative - the introduction is to be understood as a blow to the head of a pirate captain, bloodthirsty and starving for adventure, perhaps by a cannonball; and then the music which follows is meant to tell his dishonorable tale using the old shanty which is so well-known. Jared understood how metal pirates are.',
          src: require('../assets/music/yo-ho.mp3')
        },
        {
          title: 'A Tender Heart',
          description: 'A stream of instrumentals followed after "Breathe In, Breathe Out", as Jared was not very happy with his vocal performance in that piece. The first was called "A Tender Heart", where a dive into synthesizer-driven productions would create a song with a distinctly electronic feeling. Instrumentation here was highly experimental. The theme of the song is to be understood as the inner thoughts of an individual who produces a strong, unfeeling exterior, but feels inside the same as any other person might.',
          src: require('../assets/music/a-tender-heart.mp3')
        },
        {
          title: 'Noonquill',
          description: 'Venturing further into experimental sonic spaces, in this piece Jared is merging a bright horn section (with which he will be come somewhat obsessed for a while) with synthesizers, in a few standard electronic styles. The music expresses nostalgia and escapism, most particularly embracing the notion that a disruption of one\'s routine can be the healthiest thing in the world. Strong funk and big-band influences are heard here.',
          src: require('../assets/music/noonquill.mp3')
        },
        {
          title: 'How To Sneak Up On An Elephant In Jazz Shoes',
          description: 'The introduction of the song, like many of those produced during this time, presents something initially quite different from what follows in the song content. This piece was imagined as romp for a skillful rock / funk band led by an organ and with a sizzling horn section, something like the Meters might have produced if they added a few more instruments.',
          src: require('../assets/music/how-to-sneak-up-on-an-elephant.mp3')
        },
        {
          title: 'Shake Hands With That Funky Alien',
          description: 'The instrumentation of this track is more standardized across the last few, and seeks to present a similar sort of song; a rock band with a synthesizer and horn section attempt to lay down the funk as the soundtrack for an intergalactic dance battle. The cheerful bridge with overtones of "It\'s A Small World" would be suitable for such a meeting, perhaps in the parking lot of a Walmart.',
          src: require('../assets/music/shake-hands-with-that-alien.mp3')
        },
        {
          title: 'Be My Star',
          description: 'Sage, Jared\'s daughter, contributed her voice to a first recording for her as she singings the titular line to this song, which also includes Jared\'s singing. The song itself draws attention to the need, all across history, for an older generation to point the right way for the younger generation, and to leave them a world that is better than the one they were given.',
          src: require('../assets/music/be-my-star.mp3')
        },
        {
          title: 'Rescue Of The Donners',
          description: 'In answer to a bizarre music-making challenge, Jared produced this emotional and educational track where his spoken voice talks about the gruesome historical event referenced in the title. The gripping sound of a scream through a foggy, icy crag sets the stage for this somewhat dramatic presentation.',
          src: require('../assets/music/rescue-of-the-donners.mp3')
        },
        {
          title: 'Talk',
          description: 'Themes of the power of communication are presented various in this song, which is a heartfelt effort of Jared\'s to explain a few things: the struggle of when communication breaks down between people that love each other, and the role that their communication plays in bringing them together again. The anthemic refrain at the end of "O - Ah" reflects the Latin verb meaning "to cry".',
          src: require('../assets/music/talk.mp3')
        },
        {
          title: 'Remind Me Later',
          description: 'Unexpectedly, and for no good reason (as none of his music had yet been received with any sort of excitement broadly, or even particularly noticed by anyone outside his circle of friends, Jared decided to start making videos for his music. This first one (and many to follow) was an instrumental, and the video consists of a single shot on his beloved cat, Mister Mouse, as said cat attempts to nap while apparently annoyed by a kaleidoscope of lighting effects around him. The song content is meant to be about the virtues of laziness, with the cat as a prime example.',
          src: require('../assets/music/remind-me-later.mp3')
        },
        {
          title: 'See You In Another Life',
          description: 'In recent years, Jared had to let go of a friendship which had lasted a long time and through important phases of his life. As an emotional working out of that event\'s impact on him, Jared wrote this song to present a range of feelings about how you find a new way of living, after cutting a toxic part of yourself away. The title borrows from a quote by the Scottish character Desmond in the TV show "Lost", and implies all of the same meaning as in that context.',
          src: require('../assets/music/see-you-in-another-life.mp3')
        },
        {
          title: 'Where The Time Goes',
          description: 'There is a well-known expression in English, "Who knows where the time goes?" Jared sets out in this song to generate a sort of musical answer to that question. The video features a lot of temporal imagery, including dancers, a water fountain, and some other interesting footage which was taken from public domain sources.',
          src: require('../assets/music/where-the-time-goes.mp3')
        },
        {
          title: 'When I See Friday',
          description: 'As a regular adult who works for a living doing things that aren\'t always tied to his happiness or personal satisfaction, Jared understood the immense, releasing and enlivening feeling of approaching a weekend, where work concerns could be set aside and serious fun could be gotten to. This song is a capturing of that feeling. The video Jared made for this involves a trip to a beach, as a potent symbol of that moment in some people\'s experience.',
          src: require('../assets/music/when-i-see-friday.mp3')
        },
        {
          title: 'Love, I Lean On You',
          description: 'The most deeply personal song in a while, Jared wrote "Love" to try and express together a few things: the helplessness of the one who is loved, if that love is removed, the total devotion which could only come from such a relationship where need features so prominently, as in the sacred relationship between a mother and her child. In the video for this song, Jared selected an old black and white film called "The Secret Life Of The Cat" which shows a litter of cats being born, and the care given the new kittens by their mother. This song was dedicated by Jared to his wife, Erin.',
          src: require('../assets/music/love-i-lean-on-you.mp3')
        },
        {
          title: 'The Noise In My Head',
          description: 'Many people know the condition of having so many thoughts running through their mind that they are unable to concentrate on the thing before them. Jared wanted to express that feeling, along with the frustration that goes with it, in a musical and video form. Heavy distorted guitars back a synthesizer lead, with the horn and organ band setup used in a good number of songs this year.',
          src: require('../assets/music/the-noise-in-my-head.mp3')
        },
        {
          title: 'Sleep',
          description: 'This song is about something everyone knows - sleep is wonderful. The video for this one features a lot of footage of blooming flowers and the rejuvenation of the world in spring, mostly shot on the property around his home in Olympia. Sleep here is positioned in two ways, as a force bringing life back into a drained world, and also as an escape from the difficulties of waking life, many as they are. Jared does a little screaming in this song, which has not happened often.',
          src: require('../assets/music/sleep.mp3')
        },
      ],
    },
  ];
  
  export default musicData;
  