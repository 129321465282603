// src/components/Games.js
import React from 'react';

const Games = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h2>Games</h2>
      <p>This page is currently under construction. Please check back soon!</p>
    </div>
  );
};

export default Games;
